import React, { useState, useEffect, useRef } from 'react'
import styles from '../assets/styles/landingPage/landingPage.module.css';
import Header from '../components/landingPage/header/Header';
import Main from '../components/landingPage/main/Main';
import Footer from '../components/landingPage/footer/Footer';
import MobileMenu from '../components/landingPage/global/MobileMenu';
import scrollToTop from '../assets/images/landing_page/scroll-to-top.png'
import Goals from '../components/landingPage/main/Goals';
import Competencies from '../components/landingPage/main/Competencies';
import Team from '../components/landingPage/main/Team';
import Contact from '../components/landingPage/main/Contact';
import Introduction from '../components/landingPage/header/Introduction';

function LandingPage() {
  const [isOpen, setIsOpen] = useState(false);
  const introRef = useRef(null);
  const goalsRef = useRef(null);
  const competenciesRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);


  /* const sectionRefs = {
    intro: introRef,
    goals: goalsRef,
    competencies: competenciesRef,
    team: teamRef,
    contact: contactRef
      <NavLink>{t('landingPage.headerStrings.navMenu.aboutUs')}</NavLink>
          </li>
          <li>
            <NavLink>{t('landingPage.headerStrings.navMenu.goals')}</NavLink>
          </li>
          <li>
            <NavLink>{t('landingPage.headerStrings.navMenu.team')}</NavLink>
          </li>
          <li>
            <NavLink>{t('landingPage.headerStrings.navMenu.competencies')}</NavLink>
          </li>
          <li>
            <NavLink>{t('landingPage.headerStrings.navMenu.contact')}</NavLink>
  } */


  const sectionRefs = [
    {
      section: "landingPage.headerStrings.navMenu.aboutUs",
      action: (block) => introRef.current.scrollIntoView({ behavior: "smooth", block: block })
    },
    {
      section: "landingPage.headerStrings.navMenu.goals",
      action: (block) => goalsRef.current.scrollIntoView({ behavior: "smooth", block: block })
    },
    {
      section: "landingPage.headerStrings.navMenu.team",
      action: (block) => teamRef.current.scrollIntoView({ behavior: "smooth", block: block })
    },
    {
      section: "landingPage.headerStrings.navMenu.competencies",
      action: (block) => competenciesRef.current.scrollIntoView({ behavior: "smooth", block: block })
    },
    {
      section: "landingPage.headerStrings.navMenu.contact",
      action: (block) => contactRef.current.scrollIntoView({ behavior: "smooth", block: block })
    },
  ]


  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute("style", "overflow: hidden; position: fixed;");
    } else document.body.setAttribute("style", "overflow: visible; position: static;");

    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className={styles.container} id='outer-container'>
      <MobileMenu openMenu={{ isOpen, setIsOpen }} sectionRefs={sectionRefs} />
      <div id='page-wrap'>
        <Header openMenu={{ isOpen, setIsOpen }} sectionRefs={sectionRefs}>
          <Introduction introRef={introRef} />
        </Header>
        <Main>
          <Goals goalsRef={goalsRef} />
          <Competencies competenciesRef={competenciesRef} />
          <Team teamRef={teamRef} />
          <Contact contactRef={contactRef} />
        </Main>
        <Footer />
      </div>
      <div onClick={scrollTop} className='scroll-to-top'>
        <img src={scrollToTop} alt='arrow' />
      </div>
    </div>
  )
}

export default LandingPage