import React, { useState, useEffect } from 'react'
import styles from '../../../assets/styles/landingPage/header/hero.module.css';
import { useTranslation } from 'react-i18next';
import TreeDesktopSvg from './TreeDesktopSvg';
import TreeMobileSvg from './TreeMobileSvg';
import TitleLabel from '../global/TitleLabel';
import { sanitize } from 'dompurify';
function Hero() {
  const { t } = useTranslation();
  const [cityTrigger, setCityTrigger] = useState(false);
  const [grassTrigger, setGrassTrigger] = useState(false)
  const [treeTrigger, setTreeTrigger] = useState(false);
  const [circleOneTrigger, setCircleOneTrigger] = useState(false);
  const [circleTwoTrigger, setCircleTwoTrigger] = useState(false);
  const [circleThreeTrigger, setCircleThreeTrigger] = useState(false);
  const [circleFourTrigger, setCircleFourTrigger] = useState(false);
  const [lineOneTrigger, setLineOneTrigger] = useState(false);
  const [lineTwoTrigger, setLineTwoTrigger] = useState(false);
  const [lineThreeTrigger, setLineThreeTrigger] = useState(false);
  const [lineFourTrigger, setLineFourTrigger] = useState(false);
  const [glowTrigger, setGlowTrigger] = useState(false);

  const TIME_BETWEEN_LOAD = 200;


  const triggersDesktop = [
    setCityTrigger,
    setGrassTrigger,
    setTreeTrigger,
    setCircleOneTrigger,
    setCircleTwoTrigger,
    setCircleThreeTrigger,
    setCircleFourTrigger,
    setLineOneTrigger,
    setLineTwoTrigger,
    setLineThreeTrigger,
    setLineFourTrigger,
    setGlowTrigger,
  ]

  const triggersMobile = [
    setCityTrigger,
    setGrassTrigger,
    setTreeTrigger,
    setCircleOneTrigger,
    setCircleTwoTrigger,
    setCircleThreeTrigger,
    setCircleFourTrigger,
    setGlowTrigger,
  ]

  const triggerFormat = () => {
    if (window.innerWidth < 1050) {
      return triggersMobile
    } else return triggersDesktop
  }

  const addTimeouts = () => {
    const timeouts = []
    const triggers = triggerFormat();
    for (let i = 0; i < triggers.length; i++) {
      let time = (i + 1) * TIME_BETWEEN_LOAD
      timeouts.push({
        trigger: () => {
          setTimeout(() => {
            triggers[i](true)
          }, time)
        }
      })
    }
    return timeouts;
  }

  useEffect(() => {
    const timeoutsArr = addTimeouts();
    timeoutsArr.forEach((timeout) => (
      timeout.trigger()
    ))
    return () => {
      timeoutsArr.forEach((timeout) => (
        clearTimeout(timeout.trigger)
      ))
    }
  });



  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={`${styles.left}`}>
          <TitleLabel title={t('landingPage.headerStrings.titleLabel')} />
          <h1 style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: sanitize(t('landingPage.headerStrings.headline')) }}></h1>
          <p dangerouslySetInnerHTML={{ __html: sanitize(t('landingPage.headerStrings.subText')) }}></p>
        </div>
        <div className={styles.right}>
          <TreeDesktopSvg styles={styles} treeTrigger={treeTrigger} />
          <TreeMobileSvg styles={styles} treeTrigger={treeTrigger} />
          <div className={styles.circles}>
            <div className={`${circleOneTrigger ? styles.apearAnimation : ''}`}>
              <img className={styles.circle} src={require('../../../assets/images/landing_page/header/circle_one.png')} alt='circle' />
              <div className={`${lineOneTrigger ? styles.apearAnimation : ''}`}>
                <p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: sanitize(t('landingPage.headerStrings.circleText.lineOne')) }}></p>
                <svg width="58" height="24" viewBox="0 0 58 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.80857 1.11429C0.490981 1.7723 -0.0437198 3.37384 0.614287 4.69143C1.27229 6.00902 2.87383 6.54372 4.19142 5.88571C5.50902 5.2277 6.04372 3.62617 5.38571 2.30857C4.7277 0.990982 3.12616 0.456284 1.80857 1.11429ZM53.309 18.1144C51.9914 18.7724 51.4567 20.3739 52.1147 21.6915C52.7727 23.0091 54.3743 23.5438 55.6918 22.8858C57.0094 22.2278 57.5441 20.6263 56.8861 19.3087C56.2281 17.9911 54.6266 17.4564 53.309 18.1144ZM2.84327 3.9748L54.3437 20.9749L54.6572 20.0253L3.15673 3.0252L2.84327 3.9748Z" fill="#78C66B" />
                </svg>
              </div>
              <div className={`${lineTwoTrigger ? styles.apearAnimation : ''}`}>
                <svg width="58" height="22" viewBox="0 0 58 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M53.8087 16.6143C52.4911 17.2723 51.9564 18.8738 52.6144 20.1914C53.2724 21.509 54.874 22.0437 56.1916 21.3857C57.5091 20.7277 58.0438 19.1261 57.3858 17.8085C56.7278 16.4909 55.1263 15.9562 53.8087 16.6143ZM2.30857 0.614289C0.990983 1.2723 0.456283 2.87383 1.11429 4.19143C1.7723 5.50902 3.37384 6.04372 4.69143 5.38571C6.00902 4.7277 6.54372 3.12616 5.88571 1.80857C5.22771 0.490981 3.62617 -0.0437176 2.30857 0.614289ZM55.1485 18.5225L3.64835 2.52251L3.35166 3.47749L54.8518 19.4775L55.1485 18.5225Z" fill="#78C66B" />
                </svg>
                <p dangerouslySetInnerHTML={{ __html: sanitize(t('landingPage.headerStrings.circleText.lineTwo')) }}></p>
              </div>
            </div>
            <div className={`${circleTwoTrigger ? styles.apearAnimation : ''}`}>
              <img className={styles.circle} src={require('../../../assets/images/landing_page/header/circle_two.png')} alt='circle' />
            </div>
            <div className={`${circleThreeTrigger ? styles.apearAnimation : ''}`}>
              <img className={styles.circle} src={require('../../../assets/images/landing_page/header/circle_three.png')} alt='circle' />
              <div className={`${lineThreeTrigger ? styles.apearAnimation : ''}`}>
                <p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: sanitize(t('landingPage.headerStrings.circleText.lineThree')) }}></p>
                <svg width="34" height="62" viewBox="0 0 34 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.30856 56.6143C0.99097 57.2723 0.456271 58.8738 1.11428 60.1914C1.77228 61.509 3.37382 62.0437 4.69142 61.3857C6.00901 60.7277 6.54371 59.1262 5.8857 57.8086C5.22769 56.491 3.62615 55.9563 2.30856 56.6143ZM29.8086 0.61429C28.491 1.2723 27.9563 2.87383 28.6143 4.19143C29.2723 5.50902 30.8738 6.04372 32.1914 5.38571C33.509 4.7277 34.0437 3.12617 33.3857 1.80857C32.7277 0.490982 31.1262 -0.0437171 29.8086 0.61429ZM3.94879 59.2204L31.4488 3.2204L30.5512 2.7796L3.05118 58.7796L3.94879 59.2204Z" fill="#78C66B" />
                </svg>
              </div>
            </div>
            <div className={`${circleFourTrigger ? styles.apearAnimation : ''}`}>
              <img className={styles.circle} src={require('../../../assets/images/landing_page/header/circle_four.png')} alt='circle' />
              <div className={`${lineFourTrigger ? styles.apearAnimation : ''}`}>
                <p dangerouslySetInnerHTML={{ __html: sanitize(t('landingPage.headerStrings.circleText.lineFour')) }}></p>
                <svg width="62" height="54" viewBox="0 0 62 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.8086 0.614283C0.491005 1.27229 -0.0436938 2.87383 0.614313 4.19142C1.27232 5.50901 2.87386 6.04371 4.19145 5.3857C5.50904 4.7277 6.04374 3.12616 5.38573 1.80857C4.72773 0.490975 3.12619 -0.043724 1.8086 0.614283ZM57.8104 48.2026C56.4929 48.8606 55.9582 50.4621 56.6162 51.7797C57.2742 53.0973 58.8757 53.632 60.1933 52.974C61.5109 52.316 62.0456 50.7145 61.3876 49.3969C60.7296 48.0793 59.128 47.5446 57.8104 48.2026ZM2.67625 3.38101L58.6781 50.9693L59.3256 50.2073L3.32379 2.61898L2.67625 3.38101Z" fill="#78C66B" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.backgroundCity} ${cityTrigger ? styles.apearAnimation : ''}`}></div>
      <div className={`${styles.grass} ${grassTrigger ? styles.apearAnimation : ''}`}>
        <div className={styles.filler}></div>
      </div>
      <div className={`${styles.glow} ${glowTrigger ? styles.apearAnimation : ''}`}></div>
    </div>
  )
}

export default Hero