import React, { useState, useEffect } from 'react'
import styles from '../../../assets/styles/landingPage/main/main.module.css';
function Main(props) {

  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTrigger(true)
    }, 800);
  }, []);
  return (
    <main className={`${styles.container} ${trigger ? styles.apearAnimation : ''}`}>
      {props.children}
    </main>
  )
}

export default Main