import React from 'react'
import i18next from 'i18next'

function CompetenceCard({ image, headline, text, styles }) {
  return (
    <div className={styles.card} style={{ height: i18next.language === 'en' ? 220 : 200 }}>
      <img src={image} alt="icon" />
      <div>
        <h4>{headline}</h4>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default CompetenceCard