import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['sr', 'en'],
   /*  lng: 'sr', this always sets the language to serbian*/
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    fallbackLng: 'sr',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: './assets/locales/{{lng}}/translation.json'
    },
    react: {useSuspense: false}
  });

  export default i18n;