
export const languages = [
  {
    value: 'sr',
    label: 'Srpski',
    flag: require('./flags/sr.png')
  },
  {
    value: 'en',
    label: 'English',
    flag: require('./flags/en.png')
  }
]

export function activeLanguageFlag (lang) {
  switch (lang) {
    case 'en':
      return require('./flags/en.png')
    case 'sr':
      return require('./flags/sr.png')
    default: return
  }
}