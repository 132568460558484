import React from 'react'
import styles from '../../../assets/styles/landingPage/main/contact.module.css';
import { useTranslation } from 'react-i18next';
import TitleLabel from '../global/TitleLabel';
import img1 from '../../../assets/images/team/minja_circle.png';
import img2 from '../../../assets/images/team/natasa_circle.png';
import img3 from '../../../assets/images/team/dubravka_circle.png';
import { sanitize } from 'dompurify';
function Contact({ contactRef }) {
  const { t } = useTranslation();
  return (
    <section className={styles.container} ref={contactRef}>
      <div className={styles.innerContainer}>
        <div className={styles.left}>
          <TitleLabel title={t("landingPage.mainStrings.contact.titleLabel")} />
          <h1 dangerouslySetInnerHTML={{ __html: sanitize(t("landingPage.mainStrings.contact.headline")) }}></h1>
          <p>{t("landingPage.mainStrings.contact.subText")}</p>
        </div>
        <div className={styles.right}>
          <div className={styles.contactItem}>
            <div className={styles.contactImage}>
              <img src={img1} alt='contact_image' />
              <div className={styles.status}>
                <div></div>
              </div>
            </div>
            <div className={styles.socialIcons}>
              <a href='https://www.linkedin.com/in/minja-obradovic-46b3676/' target="_blank" rel="noreferrer">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="20" fill="#153060" fillOpacity="0.1" />
                  <path d="M25.6008 12.8C26.0251 12.8 26.4321 12.9686 26.7321 13.2687C27.0322 13.5687 27.2008 13.9757 27.2008 14.4V25.6C27.2008 26.0244 27.0322 26.4314 26.7321 26.7314C26.4321 27.0315 26.0251 27.2 25.6008 27.2H14.4008C13.9764 27.2 13.5695 27.0315 13.2694 26.7314C12.9694 26.4314 12.8008 26.0244 12.8008 25.6V14.4C12.8008 13.9757 12.9694 13.5687 13.2694 13.2687C13.5695 12.9686 13.9764 12.8 14.4008 12.8H25.6008ZM25.2008 25.2V20.96C25.2008 20.2684 24.926 19.605 24.4369 19.1159C23.9478 18.6268 23.2845 18.352 22.5928 18.352C21.9128 18.352 21.1208 18.768 20.7368 19.392V18.504H18.5048V25.2H20.7368V21.256C20.7368 20.64 21.2328 20.136 21.8488 20.136C22.1458 20.136 22.4307 20.254 22.6407 20.4641C22.8508 20.6741 22.9688 20.959 22.9688 21.256V25.2H25.2008ZM15.9048 17.248C16.2612 17.248 16.6031 17.1064 16.8551 16.8544C17.1072 16.6024 17.2488 16.2605 17.2488 15.904C17.2488 15.16 16.6488 14.552 15.9048 14.552C15.5462 14.552 15.2023 14.6945 14.9488 14.948C14.6952 15.2016 14.5528 15.5455 14.5528 15.904C14.5528 16.648 15.1608 17.248 15.9048 17.248ZM17.0168 25.2V18.504H14.8008V25.2H17.0168Z" fill="#153060" />
                </svg>
              </a>
              <a href='mailto:minja.obradovic@grit2022.rs'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="20" fill="#153060" fillOpacity="0.1" />
                  <path d="M12.8 27C12.305 27 11.8814 26.8288 11.5292 26.4864C11.1764 26.1434 11 25.7313 11 25.25V14.75C11 14.2687 11.1764 13.8569 11.5292 13.5145C11.8814 13.1715 12.305 13 12.8 13H27.2C27.695 13 28.1189 13.1715 28.4717 13.5145C28.8239 13.8569 29 14.2687 29 14.75V25.25C29 25.7313 28.8239 26.1434 28.4717 26.4864C28.1189 26.8288 27.695 27 27.2 27H12.8ZM20 20.7219C20.075 20.7219 20.1536 20.7108 20.2358 20.6886C20.3186 20.667 20.3975 20.6344 20.4725 20.5906L26.84 16.7188C26.96 16.6458 27.05 16.5548 27.11 16.4458C27.17 16.3361 27.2 16.2156 27.2 16.0844C27.2 15.7927 27.0725 15.574 26.8175 15.4281C26.5625 15.2823 26.3 15.2896 26.03 15.45L20 19.125L13.97 15.45C13.7 15.2896 13.4375 15.2858 13.1825 15.4386C12.9275 15.592 12.8 15.8073 12.8 16.0844C12.8 16.2302 12.83 16.3577 12.89 16.4667C12.95 16.5764 13.04 16.6604 13.16 16.7188L19.5275 20.5906C19.6025 20.6344 19.6814 20.667 19.7642 20.6886C19.8464 20.7108 19.925 20.7219 20 20.7219Z" fill="#153060" />
                </svg>
              </a>
            </div>
          </div>
          <div className={styles.contactItem}>
            <div className={styles.contactImage}>
              <img src={img2} alt='contact_image' />
              <div className={styles.status}>
                <div></div>
              </div>
            </div>
            <div className={styles.socialIcons}>
              <a href='https://www.linkedin.com/in/natasa-sekulic-mba-72166253/' target="_blank" rel="noreferrer">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="20" fill="#153060" fillOpacity="0.1" />
                  <path d="M25.6008 12.8C26.0251 12.8 26.4321 12.9686 26.7321 13.2687C27.0322 13.5687 27.2008 13.9757 27.2008 14.4V25.6C27.2008 26.0244 27.0322 26.4314 26.7321 26.7314C26.4321 27.0315 26.0251 27.2 25.6008 27.2H14.4008C13.9764 27.2 13.5695 27.0315 13.2694 26.7314C12.9694 26.4314 12.8008 26.0244 12.8008 25.6V14.4C12.8008 13.9757 12.9694 13.5687 13.2694 13.2687C13.5695 12.9686 13.9764 12.8 14.4008 12.8H25.6008ZM25.2008 25.2V20.96C25.2008 20.2684 24.926 19.605 24.4369 19.1159C23.9478 18.6268 23.2845 18.352 22.5928 18.352C21.9128 18.352 21.1208 18.768 20.7368 19.392V18.504H18.5048V25.2H20.7368V21.256C20.7368 20.64 21.2328 20.136 21.8488 20.136C22.1458 20.136 22.4307 20.254 22.6407 20.4641C22.8508 20.6741 22.9688 20.959 22.9688 21.256V25.2H25.2008ZM15.9048 17.248C16.2612 17.248 16.6031 17.1064 16.8551 16.8544C17.1072 16.6024 17.2488 16.2605 17.2488 15.904C17.2488 15.16 16.6488 14.552 15.9048 14.552C15.5462 14.552 15.2023 14.6945 14.9488 14.948C14.6952 15.2016 14.5528 15.5455 14.5528 15.904C14.5528 16.648 15.1608 17.248 15.9048 17.248ZM17.0168 25.2V18.504H14.8008V25.2H17.0168Z" fill="#153060" />
                </svg>
              </a>
              <a href='mailto:natasa.sekulic@grit2022.rs'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="20" fill="#153060" fillOpacity="0.1" />
                  <path d="M12.8 27C12.305 27 11.8814 26.8288 11.5292 26.4864C11.1764 26.1434 11 25.7313 11 25.25V14.75C11 14.2687 11.1764 13.8569 11.5292 13.5145C11.8814 13.1715 12.305 13 12.8 13H27.2C27.695 13 28.1189 13.1715 28.4717 13.5145C28.8239 13.8569 29 14.2687 29 14.75V25.25C29 25.7313 28.8239 26.1434 28.4717 26.4864C28.1189 26.8288 27.695 27 27.2 27H12.8ZM20 20.7219C20.075 20.7219 20.1536 20.7108 20.2358 20.6886C20.3186 20.667 20.3975 20.6344 20.4725 20.5906L26.84 16.7188C26.96 16.6458 27.05 16.5548 27.11 16.4458C27.17 16.3361 27.2 16.2156 27.2 16.0844C27.2 15.7927 27.0725 15.574 26.8175 15.4281C26.5625 15.2823 26.3 15.2896 26.03 15.45L20 19.125L13.97 15.45C13.7 15.2896 13.4375 15.2858 13.1825 15.4386C12.9275 15.592 12.8 15.8073 12.8 16.0844C12.8 16.2302 12.83 16.3577 12.89 16.4667C12.95 16.5764 13.04 16.6604 13.16 16.7188L19.5275 20.5906C19.6025 20.6344 19.6814 20.667 19.7642 20.6886C19.8464 20.7108 19.925 20.7219 20 20.7219Z" fill="#153060" />
                </svg>
              </a>
            </div>
          </div>
          <div className={styles.contactItem}>
            <div className={styles.contactImage}>
              <img src={img3} alt='contact_image' />
              <div className={styles.status}>
                <div></div>
              </div>
            </div>
            <div className={styles.socialIcons}>
              <a href='https://www.linkedin.com/in/dubravka-zivkovic-78a6875/' target="_blank" rel="noreferrer">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="20" fill="#153060" fillOpacity="0.1" />
                  <path d="M25.6008 12.8C26.0251 12.8 26.4321 12.9686 26.7321 13.2687C27.0322 13.5687 27.2008 13.9757 27.2008 14.4V25.6C27.2008 26.0244 27.0322 26.4314 26.7321 26.7314C26.4321 27.0315 26.0251 27.2 25.6008 27.2H14.4008C13.9764 27.2 13.5695 27.0315 13.2694 26.7314C12.9694 26.4314 12.8008 26.0244 12.8008 25.6V14.4C12.8008 13.9757 12.9694 13.5687 13.2694 13.2687C13.5695 12.9686 13.9764 12.8 14.4008 12.8H25.6008ZM25.2008 25.2V20.96C25.2008 20.2684 24.926 19.605 24.4369 19.1159C23.9478 18.6268 23.2845 18.352 22.5928 18.352C21.9128 18.352 21.1208 18.768 20.7368 19.392V18.504H18.5048V25.2H20.7368V21.256C20.7368 20.64 21.2328 20.136 21.8488 20.136C22.1458 20.136 22.4307 20.254 22.6407 20.4641C22.8508 20.6741 22.9688 20.959 22.9688 21.256V25.2H25.2008ZM15.9048 17.248C16.2612 17.248 16.6031 17.1064 16.8551 16.8544C17.1072 16.6024 17.2488 16.2605 17.2488 15.904C17.2488 15.16 16.6488 14.552 15.9048 14.552C15.5462 14.552 15.2023 14.6945 14.9488 14.948C14.6952 15.2016 14.5528 15.5455 14.5528 15.904C14.5528 16.648 15.1608 17.248 15.9048 17.248ZM17.0168 25.2V18.504H14.8008V25.2H17.0168Z" fill="#153060" />
                </svg>
              </a>
              <a href='mailto:dubravka.zivkovic@grit2022.rs'>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="20" fill="#153060" fillOpacity="0.1" />
                  <path d="M12.8 27C12.305 27 11.8814 26.8288 11.5292 26.4864C11.1764 26.1434 11 25.7313 11 25.25V14.75C11 14.2687 11.1764 13.8569 11.5292 13.5145C11.8814 13.1715 12.305 13 12.8 13H27.2C27.695 13 28.1189 13.1715 28.4717 13.5145C28.8239 13.8569 29 14.2687 29 14.75V25.25C29 25.7313 28.8239 26.1434 28.4717 26.4864C28.1189 26.8288 27.695 27 27.2 27H12.8ZM20 20.7219C20.075 20.7219 20.1536 20.7108 20.2358 20.6886C20.3186 20.667 20.3975 20.6344 20.4725 20.5906L26.84 16.7188C26.96 16.6458 27.05 16.5548 27.11 16.4458C27.17 16.3361 27.2 16.2156 27.2 16.0844C27.2 15.7927 27.0725 15.574 26.8175 15.4281C26.5625 15.2823 26.3 15.2896 26.03 15.45L20 19.125L13.97 15.45C13.7 15.2896 13.4375 15.2858 13.1825 15.4386C12.9275 15.592 12.8 15.8073 12.8 16.0844C12.8 16.2302 12.83 16.3577 12.89 16.4667C12.95 16.5764 13.04 16.6604 13.16 16.7188L19.5275 20.5906C19.6025 20.6344 19.6814 20.667 19.7642 20.6886C19.8464 20.7108 19.925 20.7219 20 20.7219Z" fill="#153060" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact