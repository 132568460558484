import React from 'react'
import styles from '../../../assets/styles/landingPage/main/competencies.module.css';
import { useTranslation } from 'react-i18next';
import TitleLabel from '../global/TitleLabel';
import CompetenceCard from './CompetenceCard';
import cardImgOne from '../../../assets/images/landing_page/main/competence-image-1.svg';
import cardImgTwo from '../../../assets/images/landing_page/main/competence-image-2.svg';
import cardImgThree from '../../../assets/images/landing_page/main/competence-image-3.svg';
import cardImgFour from '../../../assets/images/landing_page/main/competence-image-4.svg';
import cardImgFive from '../../../assets/images/landing_page/main/competence-image-5.svg';
import cardImgSix from '../../../assets/images/landing_page/main/competence-image-6.svg';
import i18next from 'i18next';
import { sanitize } from 'dompurify';

function Competencies({ competenciesRef }) {
  const { t } = useTranslation();
  return (
    <section className={styles.container} ref={competenciesRef}>
      <div className={styles.sideImg} style={{ width: i18next.language === 'en' ? 965 : 900 }}></div>
      <div className={styles.innerContainer}>
        <div className={styles.left}>
          <TitleLabel
            title={t("landingPage.mainStrings.competencies.titleLabel")}
            bgColor={'rgba(229, 255, 77, 0.1)'}
            textColor={'#E5FF4D'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: sanitize(t("landingPage.mainStrings.competencies.headline")) }}></h1>
          <p>{t("landingPage.mainStrings.competencies.subText")}</p>
        </div>
        <div className={styles.right}>
          <CompetenceCard
            styles={styles}
            image={cardImgOne}
            headline={t("landingPage.mainStrings.competencies.cardOneHeadline")}
            text={t("landingPage.mainStrings.competencies.cardOneText")}
          />
          <CompetenceCard
            styles={styles}
            image={cardImgTwo}
            headline={t("landingPage.mainStrings.competencies.cardTwoHeadline")}
            text={t("landingPage.mainStrings.competencies.cardTwoText")}
          />
          <CompetenceCard
            styles={styles}
            image={cardImgThree}
            headline={t("landingPage.mainStrings.competencies.cardThreeHeadline")}
            text={t("landingPage.mainStrings.competencies.cardThreeText")}
          />
          <CompetenceCard
            styles={styles}
            image={cardImgFour}
            headline={t("landingPage.mainStrings.competencies.cardFourHeadline")}
            text={t("landingPage.mainStrings.competencies.cardFourText")}
          />
          <CompetenceCard
            styles={styles}
            image={cardImgFive}
            headline={t("landingPage.mainStrings.competencies.cardFiveHeadline")}
            text={t("landingPage.mainStrings.competencies.cardFiveText")}
          />
          <CompetenceCard
            styles={styles}
            image={cardImgSix}
            headline={t("landingPage.mainStrings.competencies.cardSixHeadline")}
            text={t("landingPage.mainStrings.competencies.cardSixText")}
          />
        </div>
      </div>
    </section>
  )
}

export default Competencies