import React, { useState, useEffect } from 'react'
import styles from '../../../assets/styles/landingPage/header/introduction.module.css';
import TreelineDesktop from './TreelineDesktop';
import TreeLineText from './TreeLineText';
import { useTranslation } from 'react-i18next';
import lightBulb from '../../../assets/images/landing_page/header/light_bulb.png';
import dollar from '../../../assets/images/landing_page/header/dollar.png';
import heart from '../../../assets/images/landing_page/header/heart.png';
import leaf from '../../../assets/images/landing_page/header/leaf.png';
import bee from '../../../assets/images/landing_page/header/bee.png';
import TreeLineMobile from './TreeLineMobile';
import { sanitize } from 'dompurify';
function Introduction({ introRef }) {
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTrigger(true)
    }, 800);
  }, []);
  return (
    <section className={`${styles.container} ${trigger ? styles.apearAnimation : ''}`} ref={introRef}>
      <div className={styles.innerContainer}>
        <div className={styles.treeLine}>
          <TreelineDesktop styles={styles} />
          <TreeLineMobile styles={styles} />
          <img className={styles.leaf} src={leaf} alt='leaf' />
          <TreeLineText styles={styles} />
        </div>
        <div className={styles.textBlock}>
          <h2>{t("landingPage.headerStrings.introduction.firstHeadline")}</h2>
          <p>{t("landingPage.headerStrings.introduction.firstText")}</p>
        </div>
        <div className={styles.shadowBox}>
          <div>
            <img src={lightBulb} alt='lightBulb' />
            <p dangerouslySetInnerHTML={{ __html: sanitize(t("landingPage.headerStrings.introduction.shadowBoxTextOne")) }}></p>
          </div>
          <div></div>
          <div>
            <img src={dollar} alt='dollar' />
            <p dangerouslySetInnerHTML={{ __html: sanitize(t("landingPage.headerStrings.introduction.shadowBoxTextTwo")) }}></p>
          </div>
        </div>
        <img className={styles.bee} src={bee} alt="bee" />
        <div className={styles.textBlock}>
          <h2>{t("landingPage.headerStrings.introduction.secondHeadline")}</h2>
          <p dangerouslySetInnerHTML={{ __html: sanitize(t("landingPage.headerStrings.introduction.secondText")) }}></p>
        </div>
        <img className={styles.heart} src={heart} alt="heart" />
        <div className={styles.textBlock}>
          <h2>{t("landingPage.headerStrings.introduction.thirdHeadline")}</h2>
          <p dangerouslySetInnerHTML={{ __html: sanitize(t("landingPage.headerStrings.introduction.thirdText")) }}></p>
        </div>
      </div>
    </section>
  )
}

export default Introduction