import React from 'react'
import styles from '../../../assets/styles/landingPage/footer/footer.module.css'
import logo from '../../../assets/images/landing_page/footer/logo.svg'
import { useTranslation } from 'react-i18next'
function Footer() {
  const { t } = useTranslation();
  return (
    <footer className={styles.container}>
      <div className={styles.treeMobile} />
      <div className={styles.innerContainer}>
        <div className={styles.info}>
          <img src={logo} alt='logo' />
          <p>{t("landingPage.footerStrings.infoText")}</p>
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.sectionItem}>
            <h4>{t("landingPage.footerStrings.team")}</h4>
            <ul>
              <li>
                <a href='mailto:office@grit2022.rs'>
                  office@grit2022.rs
                </a>
              </li>
              <li>
                <a href='tel: +38163326125'>
                  Tel: +38163326125
                </a>
              </li>
              <li>
                <a href='tel: +38163444097'>
                  Tel: +38163444097
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.bottomText}>
        <p>{t("landingPage.footerStrings.bottomText")}</p>
      </div>
    </footer>
  )
}

export default Footer