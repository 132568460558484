import React from 'react'
import { elastic as Menu } from 'react-burger-menu'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from '../../../assets/styles/landingPage/header/menu.module.css'
import LangSelectMobile from '../header/LangSelectMobile';
import crossIcon from '../../../assets/images/landing_page/header/close_icon.png'
import logo from '../../../assets/images/Logo.svg'
function MobileMenu({ openMenu, sectionRefs }) {
  const { t } = useTranslation()
  const { isOpen, setIsOpen } = openMenu;
  var menuStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: '36px',
      top: '36px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      position: 'absolute',
      height: '24px',
      width: '24px',
      marginTop: 20,
      marginRight: 20,
      background: 'none'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed'
    },
    bmMenu: {
      background: 'var(--green)',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      width: '87%'
    },
    bmMorphShape: {
      fill: 'var(--green)',
      width: '15%'
    },
    bmItemList: {
      color: '#b8b7ad',
      position: 'absolute',
      width: '100vw',
      height: '100%',
      left: 0,
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      /* alignItems: 'center', */
      paddingTop: 150
    },
    bmItem: {
      display: 'block',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }
  return (
    <Menu
      width={'100vw'}
      isOpen={isOpen}
      styles={menuStyles}
      onClose={() => setIsOpen(false)}
      customBurgerIcon={false}
      pageWrapId={'page-wrap'}
      outerContainerId={'outer-container'}
      customCrossIcon={<img src={crossIcon} alt='crossicon' width={20} />}
    >
      <div className={styles.container}>
        <div className={styles.menuHeader}>
          <img src={logo} alt="logo" />
        </div>
        <ul>
          {sectionRefs.map((section, index) => (
            <li key={index} onClick={() => {
              setIsOpen(false)
              setTimeout(() => {
                section.action("start")
              }, 500);
            }}>
              <NavLink>{t(section.section)}</NavLink>
            </li>
          ))}
        </ul>
        <LangSelectMobile />
        <div style={{ height: 120 }}></div>
      </div>
    </Menu>
  )
}

export default MobileMenu