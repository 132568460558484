import React, { useState, useRef, useEffect } from 'react'
import styles from '../../../assets/styles/landingPage/header/langSelectMobile.module.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { languages } from '../../../assets/languages/languages.js';


function LangSelectMobile() {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const openMenu = (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(false)
      }
    }
    document.addEventListener('mousedown', openMenu);
    return () => {
      document.removeEventListener('mousedown', openMenu);
    }
  }, [isActive]);

  return (
    <div className={styles.dropdown} ref={ref}>
      <div className={styles.dropdownBtn} onClick={e => setIsActive(!isActive)}>
        <p>{t('lang')}</p>
        <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.8" d="M0 0L2.03252 4.59153e-08L4.56911 3.60504H3.43089L5.96748 1.34807e-07L8 1.80723e-07L5.05691 4H2.94309L0 0Z" fill="white" />
        </svg>
      </div>
      <div className={`${styles.dropdownContent} ${isActive ? styles.active : ""}`}>
        {languages.map((option, index) => (
          <div key={option.value} className={`${styles.dropdownItem} ${option.value === i18next.language ? styles.selected : ''}`} onClick={() => {
            i18next.changeLanguage(option.value)
            setIsActive(false);
          }}><p>{option.label}</p></div>
        ))}
      </div>
    </div>
  )
}

export default LangSelectMobile