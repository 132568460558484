import React from 'react'

function TreeLineMobile({ styles }) {
  return (
    <div className={styles.treeLineMobile}>

    </div>
  )
}

export default TreeLineMobile