import React from 'react'
import styles from '../../../assets/styles/landingPage/main/goals.module.css'
import { useTranslation } from 'react-i18next';
import TitleLabel from '../global/TitleLabel';
import GoalCard from './GoalCard';
import goalCardImageOne from '../../../assets/images/landing_page/main/goal-card-image-1.svg';
import goalCardImageTwo from '../../../assets/images/landing_page/main/goal-card-image-2.svg';
import goalCardImageThree from '../../../assets/images/landing_page/main/goal-card-image-3.svg';
import goalCardImageFour from '../../../assets/images/landing_page/main/goal-card-image-4.svg';
import dots from '../../../assets/images/landing_page/main/goal-dots.svg'
import { sanitize } from 'dompurify';
function Goals({ goalsRef }) {
  const { t } = useTranslation();
  return (
    <section className={styles.container} ref={goalsRef}>
      <div className={styles.innerContainer}>
        <div className={styles.left}>
          <TitleLabel title={t("landingPage.mainStrings.goals.titleLabel")} />
          <h1 style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: sanitize(t("landingPage.mainStrings.goals.headline")) }}></h1>
          <p>{t("landingPage.mainStrings.goals.subText")}</p>
        </div>
        <div className={styles.right}>
          <GoalCard
            styles={styles}
            image={goalCardImageOne}
            headline={t("landingPage.mainStrings.goals.goalCardOneHeadline")}
            text={t("landingPage.mainStrings.goals.goalCardOneText")}
          />
          <GoalCard
            styles={styles}
            image={goalCardImageTwo}
            headline={t("landingPage.mainStrings.goals.goalCardTwoHeadline")}
            text={t("landingPage.mainStrings.goals.goalCardTwoText")}
          />
          <GoalCard
            styles={styles}
            image={goalCardImageThree}
            headline={t("landingPage.mainStrings.goals.goalCardThreeHeadline")}
            text={t("landingPage.mainStrings.goals.goalCardThreeText")}
          />
          <GoalCard
            styles={styles}
            image={goalCardImageFour}
            headline={t("landingPage.mainStrings.goals.goalCardFourHeadline")}
            text={t("landingPage.mainStrings.goals.goalCardFourText")}
          />
          <img className={styles.dotsTop} src={dots} alt='dots' />
          <img className={styles.dotsMid} src={dots} alt='dots' />
        </div>
      </div>
    </section>
  )
}

export default Goals