import React from 'react'
import i18next from 'i18next'

function GoalCard({ styles, headline, text, image }) {
  return (
    <div className={styles.goalCard} style={{ height: i18next.language === 'en' ? 340 : 323 }}>
      <img src={image} alt='goal card icon' />
      <h4>{headline}</h4>
      <p>{text}</p>
    </div>
  )
}

export default GoalCard