import React from 'react'

function TreeMobileSvg({ styles, treeTrigger }) {
  return (
    <div className={`${styles.treeMobile} ${treeTrigger ? styles.apearAnimation : ''}`}>
      <div></div>
      <div>
        <div></div>
      </div>
    </div>
  )
}

export default TreeMobileSvg