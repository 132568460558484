import React from 'react'
import { useTranslation } from 'react-i18next'

function TreeLineText({ styles }) {
  const { t } = useTranslation();
  return (
    <div className={styles.treeLineText}>
      <p>{t('landingPage.headerStrings.introduction.aboutUs')}</p>
    </div>
  )
}

export default TreeLineText